import ky, { HTTPError } from "ky";
import type { PageContext } from "vike/types";
import { hasInjectionContext } from "vue";

/**
 * Our server should act as a proxy for the client, so we need to forward cookies and set proxy headers
 */
function serverHeaders(pageContext: PageContext) {
  const { cookies, host, protocol } = pageContext;

  const cookieString = Object.entries(cookies ?? {})
    .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
    .join("; ");

  return {
    Cookie: cookieString,
    Origin: `${protocol}://${host}`,
    "X-Forwarded-Host": host,
    "X-Forwarded-Proto": protocol,
  };
}

export function buildApiClient(pageContext?: PageContext) {
  if (!pageContext && !hasInjectionContext()) {
    throw new Error(
      "No pageContext provided and no injection context available"
    );
  }
  const ctx = pageContext ?? usePageContext();
  const prefixUrl = import.meta.env.SSR
    ? process.env.VITE_API_SSR_HOST
    : ctx.envs.apiBaseUrl;

  return ky.create({
    prefixUrl,
    credentials: "include",
    headers: {
      Accept: "application/json",
    },
    retry: {
      limit: 2,
      statusCodes: [408, 413, 419, 429, 500, 502, 503, 504, 522, 524],
      methods: [
        "get",
        "post",
        "patch",
        "put",
        "delete",
        "head",
        "trace",
        "options",
      ],
      afterStatusCodes: [413, 429, 503],
      delay: (attemptCount) => 0.3 * 2 ** (attemptCount - 1) * 1000,
    },
    hooks: {
      beforeRequest: [
        (request) => {
          let token: string | undefined;
          if (globalThis.window === undefined) {
            token = ctx.cookies?.["XSRF-TOKEN"];
          } else {
            token = document.cookie
              .split("; ")
              .find((row) => row.startsWith("XSRF-TOKEN="))
              ?.split("=")[1];
          }

          let headers = {
            "X-Requested-With": "XMLHttpRequest",
            "X-XSRF-TOKEN": token ? decodeURIComponent(token) : undefined,
          };

          if (globalThis.window === undefined) {
            headers = {
              ...headers,
              ...serverHeaders(ctx),
            };
          }

          Object.entries(headers)
            .filter(([, value]) => Boolean(value))
            .forEach(([key, value]) => request.headers.set(key, value!));

          return request;
        },
      ],
      beforeRetry: [
        async ({ error }) => {
          if (error instanceof HTTPError && error.response.status === 419) {
            await ky("api/sanctum/csrf-cookie", {
              prefixUrl,
              credentials: "include",
            });
          }
        },
      ],
    },
  });
}
