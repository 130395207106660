import * as Sentry from "@sentry/vue";

export function useAuth() {
  const userStore = useUserStore();
  const { user } = storeToRefs(userStore);

  onServerPrefetch(userStore.refreshUser);

  onMounted(() => {
    window._paq?.push(["setUserId", user.value?.id.toString()]);

    if (user.value) {
      Sentry.setUser({ ...user.value, id: user.value.id.toString() });
    }
  });
}
